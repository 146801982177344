var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "data-table pin-table" },
    [
      _c("enso-table", {
        staticClass: "box is-paddingless raises-on-hover",
        attrs: { id: "pins" },
        scopedSlots: _vm._u([
          {
            key: "image",
            fn: function(ref) {
              var row = ref.row
              return [
                _c("div", { staticClass: "flex-centered" }, [
                  _c(
                    "figure",
                    {
                      staticClass: "image is-32x32 flex-centered",
                      staticStyle: { margin: "0 auto" }
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: _vm.route("pins.show", row.image),
                          alt: "Placeholder image"
                        }
                      })
                    ]
                  )
                ])
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }