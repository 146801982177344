<template>
    <section class="data-table pin-table">
        <enso-table class="box is-paddingless raises-on-hover" id="pins">
            <template v-slot:image="{ row }">
                <div class="flex-centered">
                    <figure class="image is-32x32 flex-centered" style="margin: 0 auto;">
                        <img :src="route('pins.show', row.image)" alt="Placeholder image">
                    </figure>
                </div>
            </template>
        </enso-table>
    </section>
</template>

<script>
import { EnsoTable } from '@enso-ui/tables/bulma';

export default {
    name: 'Index',
    components: { EnsoTable },
    inject: ['route'],
};
</script>
